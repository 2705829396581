import React from "react"

import styled from "styled-components"

import LandingPage from "../components/landingPage/landingPage"
// import Sparkle from "../components/sparkle"

const IndexPage = () => {
  return (
    <Wrapper>
      <LandingPage />
      {/* <Sparkle>
      <h1>JOSH MU</h1>
    </Sparkle> */}
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default IndexPage
